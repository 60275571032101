import { LSK_TEMP_TOKEN } from "../constants/local-storage-constants"
import { AccountInfo } from "../model/AccountInfo"
import SecureStorage from "../util/SecureStorage"
import AxiosHelper from "./AxiosHelper"

const getBuList = () => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: 'group/list-group-feature-master/224', queryParams: null })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : [])
        }
        else reject(res.description)
      }).catch(e => reject(e))
  })
}

const listOrders = (filters: any) => {
  return new Promise((resolve, reject) => {
    const requestData = {
      path: 'list-orders',
      queryParams: filters, // Pass filters as data
    };

    AxiosHelper.httpGet(requestData)
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : []);
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
}


const getOrderDetails = (orderId: string) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: `order-detail/${orderId}`, queryParams: null })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : {});
        } else {
          reject(res.description);
        }
      })
      .catch((e) => reject(e));
  });
};


const listComplaints = (filters: any) => {
  return new Promise((resolve, reject) => {
    const requestData = {
      path: 'list-complaints',
      queryParams: filters, // Pass filters as data
    };

    AxiosHelper.httpGet(requestData)
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : []);
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
}
const activeOrders = () => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: `active-orders` })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : {});
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
}
const activeComplaints = () => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: `active-complaints` })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : {});
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
}
const getComplaintDetails = (complaintId: string) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: `complaint-detail/${complaintId}`, queryParams: null })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : {});
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
}
const getDashboardData = () => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: 'dashboard-data', queryParams: null })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : {});
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
}

const getNotifications = () => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: `notifications` })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : {});
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
};

const getOfferDetails = (offerId: number) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: `offer-details/${offerId}` })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : {});
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
};

const getListOfOffers = () => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: `list-offers` })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : {});
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
};

const getReasonsList = () => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: `reasons` })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : {});
        } else {
          reject(res.description);
        }
      })
      .catch((e) => reject(e));
  });
};

const postAddComplaintData = (requestData: any) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpPost({ path: `add-complaint`, body: requestData })
      .then((res: any) => {
        //console.log(res);
        if (res.isSuccess) {
          resolve(res.data ? res : {});
        } else if (res?.errors) {
          resolve(res);
        } else {
          reject(res.description);
        }
      })
      .catch((error) => reject(error));
  });
};

const searchOrder = (orderNumber: number) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: `search-order?search=${orderNumber}` })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : {});
        } else if (res?.message) {
          // Resolve ..
          resolve(res);
          //console.log(res);
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
};

const searchComplaint = (complaintNumber: number) => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: `search-complaint?search=${complaintNumber}` })
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : {});
        } else if (res?.message) {
          // ..
          resolve(res);
          //console.log(res)
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
};

const generateOTP = (email: string) => {
  return new Promise((resolve, reject) => {
    //const tempToken = SecureStorage.getItem(LSK_TEMP_TOKEN)
    const requestData = {
      path: 'generate-otp',
      body: { email },
    };

    AxiosHelper.httpPost(requestData)
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res);
        } else if (res.data && res.data.errors) {
          // Handle validation errors
          reject(res.data.errors);
        } else {
          // Handle other API errors
          reject(res);
        }
      })
      .catch(e => reject(e));
  });
};

const validateOTP = (otp: string) => {
  return new Promise((resolve, reject) => {
    const requestData = {
      path: 'validate-otp',
      body: { otp },
    };

    AxiosHelper.httpPost(requestData)
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res);
        } else if (res.message) {
          // ..
          resolve(res);
          //console.log(res)
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
};

const getAccountInfo = (): Promise<AccountInfo> => {
  return new Promise((resolve, reject) => {
    AxiosHelper.httpGet({ path: `account-info` })
      .then((res: any) => {
        if (res.isSuccess) {
          // Assuming `res.data` is of type AccountInfo
          const accountInfo: AccountInfo = res.data;
          resolve(accountInfo);
        } else {
          reject(res.description);
        }
      })
      .catch((e) => reject(e));
  });
};

const changePassword = (password: string, passconf: string) => {
  return new Promise((resolve, reject) => {
    const requestData = {
      path: 'change-password',
      body: { password, passconf },
    };

    AxiosHelper.httpPost(requestData)
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res.data ? res.data : {});
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
};
const resetPassword = (password: string, passconf: string) => {
  return new Promise((resolve, reject) => {
    const requestData = {
      path: 'reset-password',
      body: { password, passconf },
    };

    AxiosHelper.httpPost(requestData)
      .then((res: any) => {
        if (res.isSuccess) {
          resolve(res);
        } else {
          reject(res.description);
        }
      })
      .catch(e => reject(e));
  });
};



const NetworkService = {
  getBuList,
  listOrders,
  getOrderDetails,
  listComplaints,
  activeOrders,
  activeComplaints,
  getComplaintDetails,
  getDashboardData,
  getNotifications,
  getOfferDetails,
  getListOfOffers,
  getReasonsList,
  postAddComplaintData,
  searchOrder,
  searchComplaint,
  generateOTP,
  validateOTP,
  getAccountInfo,
  changePassword,
  resetPassword
}

export default NetworkService