import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkService from "../../network/NetworkService";


export const featureMasterThunk = createAsyncThunk(
    '/group/list-group-feature-master',
    async (_, thunkApi) => await NetworkService.getBuList()
)


const _filterFmList = (featureList: Array<any>, searchQuery: string): Array<any> => {
    return searchQuery
        ? featureList
            .filter((fm: any) => fm.featureName.toLocaleLowerCase().includes(searchQuery))
        : featureList
}

const featureMasterSlice = createSlice({
    name: "featureMaster",
    initialState: {
        filteredList: [],
        featureList: [],
        isLoading: false,
        errorMessage: '',
        searchQuery: '',
    },
    reducers: {
        setSearchQuery: (state, action) => {
            state.searchQuery = action.payload
        },
        filterFmList: (state) => {
            const test = ''
            test.toLocaleLowerCase()
            state.filteredList = _filterFmList(state.featureList, state.searchQuery) as any //TODO : use data type for feature master
        }
    },
    extraReducers(builder) {
        builder
            .addCase(featureMasterThunk.pending, (state, action) => {
                state.isLoading = true
                state.errorMessage = ''
            })
            .addCase(featureMasterThunk.fulfilled, (state, action) => {
                state.featureList = action.payload as any
                state.filteredList = _filterFmList(state.featureList, state.searchQuery) as any
                state.isLoading = false
                state.errorMessage = ''
            })
            .addCase(featureMasterThunk.rejected, (state, action) => {
                state.errorMessage = action.error.message ?? ''
                state.isLoading = false
            })
    },
})

export const { setSearchQuery, filterFmList } = featureMasterSlice.actions

export default featureMasterSlice.reducer