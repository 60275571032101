import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkService from "../../network/NetworkService";

export const fetchAutocompleteSuggestions = createAsyncThunk(
    '/autocomplete-suggestions',
    async (query: string, thunkApi) => await NetworkService.listOrders(query)
);

const initialState = {
    suggestions: [] as string[],
    isLoading: false,
    errorMessage: '',
};

const autoCompleteSlice = createSlice({
    name: "autocomplete",
    initialState: {
        ...initialState,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAutocompleteSuggestions.pending, (state) => {
                state.isLoading = true;
                state.errorMessage = '';
            })
            .addCase(fetchAutocompleteSuggestions.fulfilled, (state, action: any) => {
                state.suggestions = action.payload;
                state.isLoading = false;
                state.errorMessage = '';
            })
            .addCase(fetchAutocompleteSuggestions.rejected, (state, action) => {
                state.suggestions = [];
                state.errorMessage = action.error.message ?? '';
                state.isLoading = false;
            });
    },
});

export default autoCompleteSlice.reducer;
