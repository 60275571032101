import { createSlice } from "@reduxjs/toolkit"

const navbarSlice = createSlice({
    name: "navbar",
    initialState: {
        updateSearchState: false,//to use this var
        searchVal: ''
    },
    reducers: {
        setUpdateSearchState: (state, action) => {
            state.updateSearchState = action.payload

        },
        setSearchVal: (state, action) => {
            state.searchVal = action.payload

        }
    }
})

export const { setUpdateSearchState, setSearchVal } = navbarSlice.actions //if you want to set then call this (navigates to line num 9)
export default navbarSlice.reducer;