import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const submitContactThunk = createAsyncThunk(
    '/contact/save',
    async (payload: any, thunkApi) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(payload)
            }, 1000);
        })
    }
)

interface ContactSliceType {
    name: string
    description: string
    saving: boolean
    isValid: boolean
    touched: boolean
    error?: any
}

const initialState = {
    name: '',
    description: '',
    saving: false,
    isValid: false,
    touched: false,
} as ContactSliceType

const contactSlice = createSlice({
    name: 'contact',
    initialState: initialState,
    reducers: {
        updateContact: (state, action) => {
            const { key, value } = action.payload

            // TODO : move validation removal code on debounce
            return {
                ...state,
                [key]: value,
                error: state.touched && state.error && state.error[key]
                    ? {
                        ...state.error,
                        [key]: ''
                    }
                    : state.error
            }
        },
        validateContact: (state) => {
            let error: any = {}

            if (!state.name) {
                error.name = "Name is required"
            }
            if (!state.description) {
                error.description = "Description is required"
            }

            return {
                ...state,
                error: error,
                touched: true,
                isValid: !!(!error.name && !error.description)
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(submitContactThunk.pending, (state, action) => {
                state.saving = true
            })
            .addCase(submitContactThunk.fulfilled, (state, action) => {
                return {
                    ...initialState
                }
            })
            .addCase(submitContactThunk.rejected, (state, action) => {
                return {
                    ...state,
                    saving: false,
                }
            })
    }
})

export const { updateContact, validateContact } = contactSlice.actions

export default contactSlice.reducer