import { configureStore } from "@reduxjs/toolkit";

import loginReducer from "./slice/loginSlice"
import featureMasterReducer from "./slice/featureMasterSlice"
import userReducer from "./slice/userSlice"
import contactReducer from "./slice/contactSlice"
import navbarReducer from "./slice/navbarSlice";
import orderReducer from "./slice/orderSlice";
import complaintReducer from "./slice/complaintSlice"
import autoCompleteReducer from "./slice/autoCompleteSlice"
import offerReducer from "./slice/offerSlice";
import addComplaintsReducer from "./slice/addComplaintsSlice";

export const store = configureStore({
    reducer: {
        login: loginReducer,
        featureMaster: featureMasterReducer,
        user: userReducer,
        contact: contactReducer,
        navbar: navbarReducer,
        order: orderReducer,
        complaint: complaintReducer,
        autoComplete: autoCompleteReducer,
        offer: offerReducer,
        addcomplaint: addComplaintsReducer
    },
})


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch