// Breadcrumbs.tsx
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { COMPLAINTS, HOME, OFFERS, ORDERS, PROFILE } from '../routes/routes';

const CustomBreadcrumbs = () => {
    const location = useLocation();
  // .. routes
  const routes = [
    { path: HOME,label: 'Dashboard' },
    //{ path: USER, element: <User /> },
   // { path: CONTACT, element: <Contact /> },
   { path: ORDERS, label: 'Orders' },
    { path: COMPLAINTS, label: 'Complaints'  },
    { path: OFFERS, label: 'Offers' },
    { path: PROFILE, label: 'Profile' },
    
    // routes
  ];

  const currentRouteIndex = routes.findIndex((route) => location.pathname.startsWith(route.path));

  // If the current route is found, render breadcrumbs
  if (currentRouteIndex !== -1) {
    return (
      <>
        {[HOME].includes(location.pathname) ? '' :
        <div className="d-flex gap-1 flex-column py-3 px-3 px-md-5">
          <h2 className="fs-lg-heading-med p-0 m-0">
            {routes?.find(item => item.path === location.pathname)?.label}
          </h2>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link
              color="inherit"
              component={RouterLink}
              to={routes?.find(item => item.path === '/home')?.path ?? '/'}
              style={{ textDecoration: 'none' }}
              className='sub-head2'
            >
              {routes?.find(item => item.path === '/home')?.label}
            </Link>

            <Link
              color="inherit"
              component={RouterLink}
              to={routes?.find(item => item.path === location.pathname)?.path ?? '/'}
              style={{ textDecoration: 'none' }}
              className='sub-head2'
            >
              {routes?.find(item => item.path === location.pathname)?.label}
            </Link>
          </Breadcrumbs>  
        </div> }
      </>
    );
  }

  // If the current route is not found in routes, return null (no breadcrumbs)
  return null;
};

export default CustomBreadcrumbs;
