import React, { lazy } from 'react'
import AuthLayout from '../Layout/AuthLayout';
import MainLayout from '../Layout/MainLayout';
import OpenLayout from '../Layout/OpenLayout';
import { CONTACT, HOME, LOGIN, OTP, USER, OFFER, ORDERS, COMPLAINTS, PROFILE, OFFERS } from './routes';
import { Navigate } from 'react-router-dom';

const RouteBuilder = (isLoggedIn: boolean) => {

  // Private pages
  const DashBoard = lazy(() => import("../pages/dashboard"))
  const User = lazy(() => import("../pages/userList"))
  const Contact = lazy(() => import("../pages/contact"))
  const NotFound = lazy(() => import("../pages/not-found"))
  const Offer = lazy(() => import("../pages/offer-detail"))
  // open pages
  const Login = lazy(() => import("../pages/login"))
  const Otp = lazy(() => import("../pages/otp"))
  const Orders = lazy(() => import("../pages/orders"))
  const Complaints = lazy(() => import("../pages/complaints"))
  const Profile = lazy(() => import("../pages/profile"))
  const Offers = lazy(() => import("../pages/offers"))
  return [
    {
      element: <MainLayout />,
      children:
        [
          { path: HOME, element: <DashBoard /> },
          { path: USER, element: <User /> },
          { path: CONTACT, element: <Contact /> },
          { path: COMPLAINTS, element: <Complaints /> },
          { path: ORDERS, element: <Orders /> },
          { path: OFFERS, element: <Offers /> },
          { path: OFFER, element: <Offer /> },
          { path: PROFILE, element: <Profile /> },
        ],
    },
    {
      element: <AuthLayout />,
      children:
        [
          { path: LOGIN, element: <Login /> },
          { path: OTP, element: <Otp /> },
        ],
    },
    {
      element: <OpenLayout />,
      children:
        [
          { path: "*", element: isLoggedIn ? <NotFound /> : <Navigate to={LOGIN} /> },
         
        ],
    },
  ]
}

export default RouteBuilder