import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "../../model/user/User";


export const sumbitUserThunk = createAsyncThunk(
    'user/submit',
    async (body: any, thunkApi) => {
        // TODO : call api 
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const tempBody = { ...body }
                delete tempBody.isLoading
                delete tempBody.errorMessage
                delete tempBody.searchQuery
                delete tempBody.valid

                tempBody.userList = tempBody.userList.map((user: any) => {
                    const tempUser = { ...user, error: undefined }
                    delete tempUser.error
                    return tempUser
                })

                resolve(tempBody)
            }, 1000);

        })
    }
)


const userSlice = createSlice({
    name: "user",
    initialState: {
        userList: [] as User[],
        isLoading: false,
        errorMessage: '',
        searchQuery: '',
        valid: false,
        saving: false
    },
    reducers: {
        addUser: (state) => {
            state.userList.push({ name: "", employeeId: "" } as User)
        },
        setUser: (state, action) => {
            const { index, key, value } = action.payload
            state.userList[index] = { ...state.userList[index], [key]: value }
        },
        validateUserList: (state) => {
            //TODO : validate

            let isValid = true
            let errorMessage = ''

            if (!state.userList.length) {
                isValid = false
                errorMessage = "User list is empty"
            }

            const tempList = state.userList.map(user => {
                const error: any = {}

                if (!user.name) {
                    error.name = "User Name cannot be empty"
                    isValid = false
                }

                if (!user.employeeId) {
                    error.employeeId = "Employee id cannot be empty"
                    isValid = false
                }

                return {
                    ...user,
                    error: error
                }
            })

            return state = {
                ...state,
                userList: tempList,
                valid: isValid,
                errorMessage: !isValid && !errorMessage ? "Invalid data" : errorMessage
            }

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sumbitUserThunk.pending, (state, action) => {
                state.saving = true
                state.errorMessage = ''
            })
            .addCase(sumbitUserThunk.fulfilled, (state, action) => {
                state.saving = false
                state.errorMessage = ''
            })
            .addCase(sumbitUserThunk.rejected, (state, action) => {
                state.saving = true
                state.errorMessage = action.error.message ?? 'Something went wrong'
            })
    }
})

export const { setUser, addUser, validateUserList } = userSlice.actions


export default userSlice.reducer