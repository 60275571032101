import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import NetworkService from "../../network/NetworkService"
import { Complaint, ComplaintFilter } from "../../model/ComplaintDetail"

export const complaintListThunk = createAsyncThunk(
    '/list-complaints',
    async (body: any, thunkApi) => await NetworkService.listComplaints(body)
)

export const complaintSearchThunk = createAsyncThunk(
    '/search-complaints',
    async (body: any, thunkApi) => await NetworkService.searchComplaint(body)
)

export const complaintSearchListThunk = createAsyncThunk(
    '/search-complaintslist',
    async (body: any, thunkApi) => await NetworkService.searchComplaint(body)
)

const inititalData = {
    complaintList: [] as Complaint[],
    complaintFilter: {} as ComplaintFilter,
    isLoading: false,
    errorMessage: '',
    totalCount: 0,
    rowsPerPage: 10,
    page: 1,
    // complaintStatusUpdated: false,
    complaintFilterList: [] as Complaint[],
    tempComplaintList: [] as Complaint[],
    tempCompPageNumber: 1,
    tempCompTotalCount: 0
}

const complaintSlice = createSlice({
    name: "list-complaints",
    initialState: {
        ...inititalData,
    },
    reducers: {
        setComplaintFilter: (state, action) => {
            state.complaintFilter = action.payload
        },
        setCompPage: (state, action) => {
            state.page = action.payload
        },
        setComplaintFilterList: (state, action) => {
            state.complaintFilterList = action.payload
        },
        setComplaintList: (state, action) => {
            state.complaintList = action.payload
        },
        setCompTotalPageCount: (state, action) => {
            state.totalCount = action.payload
        },
        setCompSearchPageNum: (state, action) => {
            state.page = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(complaintListThunk.pending, (state, action) => {
                state.isLoading = true
                state.errorMessage = ''
            })
            .addCase(complaintListThunk.fulfilled, (state, action: any) => {
                state.complaintList = action.payload?.complaints as Complaint[]
                state.totalCount = action.payload?.totalRecordCount as any
                state.tempComplaintList = action.payload?.complaints as Complaint[]
                state.tempCompTotalCount = action.payload?.totalRecordCount as any
                state.isLoading = false
                state.errorMessage = ''
            })
            .addCase(complaintListThunk.rejected, (state, action) => {
                state.complaintList = []
                state.totalCount = 0
                state.errorMessage = action.error.message ?? ''
                state.isLoading = false
            })


        builder
            .addCase(complaintSearchThunk.pending, (state, action) => { //pending means api call is succes
                state.isLoading = true
                state.errorMessage = ''
            })
            .addCase(complaintSearchThunk.fulfilled, (state, action: any) => { // return res
                state.complaintFilterList = action.payload?.data?.complaints?.length ? action.payload.data.complaints : []
                state.isLoading = false
                state.errorMessage = ''
            })
            .addCase(complaintSearchThunk.rejected, (state, action) => { //error , so emp 
                state.complaintFilterList = []
                // state.totalCount = 0 // pagination
                state.errorMessage = action.error.message ?? ''
                state.isLoading = false
            })

        builder
            .addCase(complaintSearchListThunk.pending, (state, action) => { //pending means api call is succes
                state.isLoading = true
                state.errorMessage = ''
            })
            .addCase(complaintSearchListThunk.fulfilled, (state, action: any) => { // return res
                state.complaintList = action.payload?.complaints as Complaint[]
                state.isLoading = false
                state.errorMessage = ''
            })
            .addCase(complaintSearchListThunk.rejected, (state, action) => { //error , so emp 
                state.complaintList = []
                // state.totalCount = 0 // pagination
                state.errorMessage = action.error.message ?? ''
                state.isLoading = false
            })


    }
})

export const { setComplaintFilter, setCompPage, setComplaintFilterList, setComplaintList, setCompTotalPageCount, setCompSearchPageNum } = complaintSlice.actions

export default complaintSlice.reducer