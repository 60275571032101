import * as React from 'react';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NorthIcon from '@mui/icons-material/North';
import Zoom from '@mui/material/Zoom';
import { IconButton, Typography } from '@mui/material';

interface Props {
  window?: () => Window;
}

function ScrollTop(props: Props) {
  const { window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
          <IconButton
          aria-label="scroll back to top"
          color="primary"
          sx={{ background: 'none', '&:hover': { background: 'none' } , color: '#0072ef'}}
        >
            <Typography variant="caption" sx={{ marginLeft: 1 , color: '#0072ef'}}>Back to top</Typography>
          <NorthIcon />
        </IconButton>
      </Box>
    </Zoom>
  );
}

export default ScrollTop;
