import { FileUploadParams, HttpGetParam, HttpPostParam } from "../model/network";
import rsAxiosInstance from "./AxiosConfig";


const httpGet = ({ path, queryParams }: HttpGetParam) => {

    //TODO: handle query params
    return new Promise((resolve, reject) => {
        rsAxiosInstance.get(path,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                params: queryParams
            })
            .then((res) => {
                return resolve(res)
            })
            .catch(e => {
                console.log(e.message)
                reject(e.message)
            }) //TODO : send formatted message
    })
}

const httpPost = ({ path, queryParams = null, body = null }: HttpPostParam) => {
    return new Promise((resolve, reject) => {
        rsAxiosInstance
            .post(
                path,
                body
            )
            .then((res) => {
                return resolve(res)
            })
            .catch(e => {
                console.log(e);
                reject(e.message);
            }) //TODO : send formatted message
    })
}

const fileUpload = ({ path, body }: FileUploadParams) => {

    return new Promise((resolve, reject) => {
        rsAxiosInstance.post(
            path,
            body,
            {
                headers: {
                    'content-type': 'multipart/form-data'
                },
            }
        ).then((res: any) => {
            return res.isSuccess ? resolve(res.data) : reject("Something went wrong")
        }).catch(e => {
            console.log(e);
            reject(e.message);
        })
    })


}

/*
    TODO: 
        image/file get helper   
        image/file upload helper
*/


const AxiosHelper = {
    httpGet,
    httpPost,
    fileUpload
}

export default AxiosHelper